/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import t from "./Accessor.js";
import { abortMaybe as o, removeMaybe as e } from "./maybe.js";
import { eachAlways as n, throwIfAbortError as s, onAbort as l } from "./promiseUtils.js";
import { property as i } from "./accessorSupport/decorators/property.js";
import { subclass as u } from "./accessorSupport/decorators/subclass.js";
function a(r, t, o) {
  return n(r.map((r, e) => t.apply(o, [r, e])));
}
async function p(r, t, o) {
  return (await n(r.map((r, e) => t.apply(o, [r, e])))).map(r => r.value);
}
function c(r) {
  return {
    ok: !0,
    value: r
  };
}
function h(r) {
  return {
    ok: !1,
    error: r
  };
}
function m(r) {
  return null != r && !0 === r.ok ? r.value : null;
}
function f(r) {
  return null != r && !1 === r.ok ? r.error : null;
}
async function _(r) {
  if (null == r) return {
    ok: !1,
    error: new Error("no promise provided")
  };
  try {
    return c(await r);
  } catch (t) {
    return h(t);
  }
}
async function b(r) {
  try {
    return c(await r);
  } catch (t) {
    return s(t), h(t);
  }
}
function y(r) {
  if (!0 === r.ok) return r.value;
  throw r.error;
}
function d(r, t) {
  return new v(r, t);
}
let v = class extends t {
  get value() {
    return m(this._result);
  }
  get error() {
    return f(this._result);
  }
  get finished() {
    return null != this._result;
  }
  constructor(r, t) {
    super({}), this._result = null, this._abortHandle = null, this.abort = () => {
      this._abortController = o(this._abortController);
    }, this.remove = this.abort, this._abortController = new AbortController();
    const {
      signal: e
    } = this._abortController;
    this.promise = r(e), this.promise.then(r => {
      this._result = c(r), this._cleanup();
    }, r => {
      this._result = h(r), this._cleanup();
    }), this._abortHandle = l(t, this.abort);
  }
  normalizeCtorArgs() {
    return {};
  }
  destroy() {
    this.abort();
  }
  _cleanup() {
    this._abortHandle = e(this._abortHandle), this._abortController = null;
  }
};
r([i()], v.prototype, "value", null), r([i()], v.prototype, "error", null), r([i()], v.prototype, "finished", null), r([i()], v.prototype, "promise", void 0), r([i()], v.prototype, "_result", void 0), v = r([u("esri.core.asyncUtils.ReactiveTask")], v);
export { y as assertResult, d as createTask, a as forEach, h as makeResultError, c as makeResultOk, p as map, _ as result, f as resultError, b as resultOrAbort, m as resultValue };